import {
  ComponentRef,
  EditorSDK,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';
import webBiLogger from '@wix/web-bi-logger';
import {
  fileShareUploadButtonAdded,
  fileShareUploadButtonSettings,
} from '@wix/bi-logger-wixlabs-users/v2';

import {
  ConnectedComponentsBuilder,
  EditorReadyFn,
  FlowAPI,
  FlowAppDefinitionId,
  FlowEditorSDK,
  FlowPlatformOptions,
  GetWidgetManifestFn,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import {
  IPanelsApi,
  PanelsApiFactory,
} from '@wix/blocks-widget-services/panels';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { getFileshareSettings, getListRoles } from './components/Widget1/model';

const presets = [{ id: 'variants-lapew5r81', type: 'DESKTOP' }];
let panelsAPI: IPanelsApi;
const openWidgetMenu = (editorSDK: FlowEditorSDK, componentRef) => {
  editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
    'token',
    'settings',
    {
      componentRef,
      configuration: {
        controls: { link: { hidden: true }, label: {}, icon: {} },
        controlGroups: {},
        states: {},
      },
    },
  );
};
const openWidgetLayout = (editorSDK: FlowEditorSDK, componentRef) => {
  editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
    'token',
    'layout',
    {
      componentRef,
    },
  );
};

let myButton: ComponentRef = null;
let sdk: FlowEditorSDK = null;
const getButtonRefandActionMethod = async (componentRef, method) => {
  if (myButton === null || myButton !== componentRef) {
    [myButton] = await sdk.components.findAllByRole('token', {
      controllerRef: componentRef,
      role: 'button1',
    });
  }
  myButton && method(sdk, myButton);
};
export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {};

export const getAppManifest = async (
  options,
  editorSDK: FlowEditorSDK,
  contextParams,
  flowAPI: FlowAPI,
) => {
  sdk = editorSDK;

  const { t } = flowAPI.translations;
  const { appManifestBuilder } = options;
  const appDefId = 'a7c8dd0e-845a-42e4-8dd4-1e5f437fcb73';
  appManifestBuilder.configureWidget(
    'a7c8dd0e-845a-42e4-8dd4-1e5f437fcb73-vfuck',
    (widgetBuilder: WidgetBuilder) => {
      widgetBuilder.configureConnectedComponents(
        'button1',
        (connectedComponentsBuilder: ConnectedComponentsBuilder) => {
          connectedComponentsBuilder.behavior().set({
            essential: { enabled: true },
            closed: { selectable: true },
          });

          connectedComponentsBuilder
            .gfpp()
            .set('mainAction1', {
              label: flowAPI.translations.t(
                'app.widget.main.action.text.icon.label',
              ),

              onClick: (event: any) => {
                const { id, componentRef } = event.detail;
                openWidgetMenu(editorSDK, componentRef);
              },
            })

            .set('link', {
              behavior: 'HIDE',
            })
            .set('layout', {
              behavior: 'HIDE',
            })
            .set('animation', {
              behavior: 'HIDE',
            });
        },
      );

      widgetBuilder.gfpp().set('mainAction2', {
        label: flowAPI.translations.t('app.widget.main.action.settings.label'),

        onClick: async (event) => {
          const { componentRef } = event.detail;
          if (!panelsAPI) {
            panelsAPI = await new PanelsApiFactory().createPanelsApi({
              editorSDK: editorSDK as any,
              editorType: options.origin.type,
              essentials: options.essentials,
            });
          }

          editorSDK.application.appStudioWidgets.props.set('token', {
            widgetRef: componentRef,
            newProps: {
              foldersList: null,
            },
          });
          const artifactOverrides =
            await editorSDK.document.info.getArtifactOverrides('');
          const revisionOverride =
            artifactOverrides[`revision-${appDefId}-override`];

          await panelsAPI.openBlocksPanel('Button Settings', componentRef, {
            overrides: {
              revision: revisionOverride ? revisionOverride : undefined,
              title: t('app.widget.main.action.settings.title'),
            },
          });

          const widgetProps =
            await editorSDK.application.appStudioWidgets.props.get('', {
              widgetRef: componentRef,
            });
          try {
            const logger = webBiLogger.factory().logger();
            Promise.all([
              getFileshareSettings(flowAPI as any),
              editorSDK.info.getMetaSiteId(''),
              editorSDK.info.getAppInstanceId(''),
              editorSDK.info.getSiteId(''),
            ])
              .then(
                async ([fileShareSettings, metaSiteId, instanceId, siteId]) => {
                  const { selectedFolderId = fileShareSettings.rootFolderId } =
                    widgetProps;

                  if (myButton === null) {
                    [myButton] = await editorSDK.components.findAllByRole(
                      'token',
                      {
                        controllerRef: componentRef,
                        role: 'button1',
                      },
                    );
                  }

                  const buttonProps: any =
                    await editorSDK.document.components.data.get('', {
                      componentRef: myButton,
                    });
                  const listRoles = await getListRoles(flowAPI, {
                    ofCurrentUser: false,
                    libraryItemId: selectedFolderId,
                    siteVisitors: false,
                  });
                  let admins_permissions = false;
                  let all_members_permissions = false;
                  let pricing_plan_permissions = false;
                  let custom_role_permissions = false;
                  listRoles.data.roles.forEach((role) => {
                    switch (role.roleType) {
                      case 'ADMINS':
                        if (
                          role.level === 'MANAGE' ||
                          role.level === 'CONTRIBUTE'
                        ) {
                          admins_permissions = true;
                        }
                        break;
                      case 'ALL_MEMBERS':
                        if (
                          role.level === 'MANAGE' ||
                          role.level === 'CONTRIBUTE'
                        ) {
                          all_members_permissions = true;
                        }
                        break;
                      case 'PLAN':
                        pricing_plan_permissions = true;
                        break;
                      default:
                        if (
                          role.level === 'MANAGE' ||
                          role.level === 'CONTRIBUTE'
                        ) {
                          custom_role_permissions = true;
                        }
                    }
                  });

                  logger.report(
                    fileShareUploadButtonSettings({
                      admins_permissions,
                      all_members_permissions,
                      app_site_id: siteId,
                      button_icon: buttonProps.svgId,
                      button_text: buttonProps.button_text,
                      custom_role_permissions,
                      hide_from_visitors_without_permissions:
                        widgetProps.hideWhenNoPermissions ? true : false,
                      instanceId,
                      instance_id: instanceId,
                      biToken: metaSiteId,
                      pricing_plan_permissions,
                      specific_folder_type: 'select_folder',
                      upload_destination_id: selectedFolderId,
                      upload_destination_type:
                        widgetProps.selectedFolderId !==
                        fileShareSettings.rootFolderId
                          ? 'specific_folder'
                          : 'main_folder',
                    }),
                  );
                },
              )
              .catch((err) => console.log('error in promise.all', err));
          } catch (err) {
            console.log('error in logger', err);
          }
        },
      });
      widgetBuilder.gfpp().set('mainAction1', {
        label: flowAPI.translations.t('app.widget.main.action.text.icon.label'),
        onClick: async (event: any) => {
          const { componentRef } = event.detail;
          getButtonRefandActionMethod(componentRef, openWidgetMenu);
        },
      });

      if (flowAPI.environment.isClassicEditor) {
        widgetBuilder.gfpp().set('design', {
          target: {
            role: 'button1',
          },
        });
      }

      widgetBuilder
        .gfpp()

        .set('layout', {
          actionId: 'widgetLayout',
        })
        .set('help', { id: 'cfc06e88-6e7d-45a6-83c4-bf7c36a8a55d' })

        .set('animation', {
          target: {
            role: 'button1',
          },
        })
        .set('stretch', {
          behavior: 'HIDE',
        })
        .set('connect', {
          behavior: 'HIDE',
        });

      widgetBuilder.configureConnectedComponents(
        'popoverButton1',
        (connectedComponentsBuilder) => {
          connectedComponentsBuilder
            .behavior()
            .set({ closed: { selectable: false } });
        },
      );
    },
  );
  const baseManifest = await fetchWidgetsStageData(options);
  return appManifestBuilder.withJsonManifest(baseManifest).build();
};
export const editorReady: EditorReadyFn = async (
  editorSDK: FlowEditorSDK,
  appDefId: FlowAppDefinitionId,
  options: FlowPlatformOptions,
  flowAPI,
) => {
  const addWidget = async (pageId: string = '') => {
    if (pageId !== '') {
      await editorSDK.document.pages.navigateTo('', {
        pageLink: { type: 'PageLink', pageId },
      });
    }
    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId: 'bfbc6216-9765-4934-b5ff-6eb6c10173e7',
      installationType: 'closed' as WidgetInstallationType,
      scopedPresets: {
        desktop: {
          layout: presets[0].id,
          style: presets[0].id,
        },
        mobile: {
          layout: presets[0].id,
          style: presets[0].id,
        },
      },
      layout: {
        height: 40,
        width: 200,
        x: 0,
        y: 100,
      },
      layouts: {
        componentLayout: {
          id: presets[0].id,
          minHeight: {
            type: 'px',
            value: 40,
          },
          hidden: false,
          height: {
            type: 'auto',
          },
          type: 'ComponentLayout',
          width: {
            type: 'percentage',
            value: 15.6,
          },
        },
        itemLayout: {
          id: presets[0].id,
          alignSelf: 'start',
          margins: {
            left: {
              type: 'percentage',
              value: 15.999284485255783,
            },
            right: {
              type: 'percentage',
              value: 0,
            },
            top: {
              type: 'px',
              value: 81.99652862548828,
            },
            bottom: {
              type: 'px',
              value: 0,
            },
          },
          gridArea: {
            rowStart: 1,
            rowEnd: 2,
            columnStart: 1,
            columnEnd: 2,
          },
          justifySelf: 'start',
          type: 'GridItemLayout',
        },
        containerLayout: {
          id: '',
          type: 'GridContainerLayout',
          rows: [
            {
              type: 'fr',
              value: 1,
            },
          ],
          columns: [
            {
              type: 'fr',
              value: 1,
            },
          ],
        },
      } as any,
    });
  };
  if (options.firstInstall) {
    addWidget();
    const logger = webBiLogger.factory().logger();
    const metaSiteId = await editorSDK.info.getMetaSiteId('');
    const instanceId = await editorSDK.info.getAppInstanceId('');
    const siteId = await editorSDK.info.getSiteId('');
    const params = {
      app_site_id: siteId,
      instanceId,
      instance_id: instanceId,
      biToken: metaSiteId,
    };

    logger.report(fileShareUploadButtonAdded(params));
  } else {
    await editorSDK.application.livePreview.refresh('', {
      shouldFetchData: true,
      source: 'CONNECTED_COMPONENT_LOADED',
    });
  }
  panelsAPI = await new PanelsApiFactory().createPanelsApi({
    editorSDK: editorSDK as any,
    editorType: options.origin.type,
    essentials: options.essentials as any,
  });

  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;

    switch (id) {
      case 'widgetTextIcon':
        getButtonRefandActionMethod(componentRef, openWidgetMenu);
        break;
      case 'widgetLayout':
        getButtonRefandActionMethod(componentRef, openWidgetLayout);
        break;
    }
  });
  await editorSDK.addEventListener('componentGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    switch (id) {
      case 'buttonTextIconPanel':
        openWidgetMenu(editorSDK, componentRef);
        break;
    }
  });

  await editorSDK.editor.setAppAPI('', {
    addWidget,
  });
};
export const exports = (editorSDK: FlowEditorSDK) => ({
  public: {
    async addWidget(pageId?: string) {
      const api = await editorSDK.editor.getAppAPI();
      return api.addWidget(pageId);
    },
  },
});
