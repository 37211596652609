import { EditorSDK } from '@wix/platform-editor-sdk';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  EditorSDK,
  flowAPI
) => {
  const {
    translations: { t },
  } = flowAPI;
  widgetBuilder.set({
    displayName: t('app.widget.name'),
    nickname: 'Widget1Widget',
  });
};
