/*
  Place where blocks model is initialized.
  Blocks model allows you to define your Widget Public APIs in a single place.
  It will automatically provide `getExports` for Editor Script and provide typings based on the model for Viewer Script (via model.createController).
*/
import {
  createBlocksModel,
  WidgetPropertyType,
} from '@wix/yoshi-flow-editor/blocks';
import { getAppSettings } from '@wix/ambassador-file-sharing-v1-settings/http';
import {
  startFileUpload,
  completeFileUpload,
  getFolderTree,
  authorizeActions,
  queryFolders,
} from '@wix/ambassador-file-sharing-v1-library-item/http';

import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { listRoles } from '@wix/ambassador-file-sharing-v1-role/http';

let fileshareSettings;
export const getFoldersList = async (flowAPI) => {
  const fileSharingClient = getFileSharingClient(flowAPI);
  let response;
  try {
    response = await fileSharingClient.request(getFolderTree({}));
  } catch (e) {
    response = 'abc';
  }
  return response;
};

export const getFolderUploadPermissions = async (flowAPI, parentFolderId) => {
  const fileSharingClient = getFileSharingClient(flowAPI);
  const permissions = await fileSharingClient.request(
    authorizeActions({ parentFolderId }),
  );
  return permissions.data.folderAuthorizedActions?.find(
    (permission) => permission.action === 'UPLOAD_FILE',
  );
};

export const getFileSharingClient = (flowAPI) => {
  return flowAPI.httpClient;
};
export const getListRoles = async (flowAPI, query: any) => {
  try {
    const fileSharingClient = getFileSharingClient(flowAPI);
    const result = await fileSharingClient.request(listRoles(query));
    return result;
  } catch (e) {
    const error: any = e;
    return error;
  }
};
export const queryFoldersApi = async (
  flowAPI,
  selectedFolderId: string,
) => {
  try {
    const fileSharingClient = getFileSharingClient(flowAPI);

    const response = await fileSharingClient.request(
      queryFolders({
        query: {
          filter: {
            id: selectedFolderId,
          },
        },
      }),
    );
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getFileshareSettings = async (flowAPI: PlatformControllerFlowAPI) => {
  if (!fileshareSettings) {
    const fileSharingClient = getFileSharingClient(flowAPI);
    try {
      fileshareSettings = (await fileSharingClient.request(getAppSettings({})))
        .data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  return fileshareSettings;
};

export const getSupportedExtensions = async (flowAPI) => {
  const settings = await getFileshareSettings(flowAPI);
  const serverSupportedExtensions = settings.supportedExtensions;

  const supportedExtensions = serverSupportedExtensions.reduce(
    (accumulator, extensionCategory) => {
      if (
        extensionCategory.category === 'VIDEO' &&
        !settings.settings.uploadSettings.allowVideoUploads
      ) {
        return accumulator;
      }
      return accumulator.concat(extensionCategory.extensions);
    },
    [],
  );
  return supportedExtensions;
};
export const formatBytes = (bytes: any, decimals = 2, flowAPI) => {
  const siteLanguage = flowAPI?.environment?.language;
  const digital = [
    'byte',
    'kilobyte',
    'megabyte',
    'gigabyte',
    'terabyte',
    'petabyte',
  ];
  if (bytes === 0) {
    const a = bytes.toLocaleString(siteLanguage, {
      style: 'unit',
      // @ts-ignore
      unit: digital[0],
      unitDisplay: 'short',
    });
    return a;
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const b = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toLocaleString(
    siteLanguage,
    {
      style: 'unit',
      // @ts-ignore
      unit: digital[i],
      unitDisplay: 'short',
    },
  );
  return b;
};
export const uploadFile = async (
  flowAPI,
  { name, size, myFile },
  parentFolderId,
  onUploadProgress,
) => {
  try {
    const formData = new FormData();
    const fileSharingClient = getFileSharingClient(flowAPI);
    const startUploadStatus = await fileSharingClient
      .request(
        startFileUpload({
          actions: [
            {
              name,
              sizeInBytes: size,
              parentFolderId,
            },
          ],
        }),
      )
      .catch(async (e) => {
        throw e;
      });
    const { urls } = startUploadStatus?.data;
    const uploadURL: any = urls?.[0];

    if (uploadURL) {
      formData.append('file', myFile, name);

      Object.keys(uploadURL.requestParameters).forEach((key) =>
        formData.append(key, uploadURL.requestParameters[key]),
      );
      const res = await fileSharingClient.post(uploadURL.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
      });
      const uploadResponse = JSON.stringify(await res.data);
      await fileSharingClient
        .request(
          completeFileUpload({
            actions: [
              {
                parentFolderId,
                uploadResponse,
              },
            ],
          }),
        )
        .catch((e) => {
          throw e;
        });
    } else if (startUploadStatus.data.failures?.length !== 0) {
      startUploadStatus.data.failures?.map((e) => {
        throw e;
      });
    }
  } catch (e: any) {
    return false;
  }
  return true;
};

export default createBlocksModel({
  widgetName: 'Widget1',
  props: {
    // Here you can define your widget props
    selectedFolderId: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
      description: 'This is the description of the prop',
    },
    foldersList: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
      description: 'This is the description of the prop',
    },
    supportedExtensions: {
      type: WidgetPropertyType.STRING,
      defaultValue: '*.*',
      description: 'Media supported extensions',
    },
    showDestinationFolder: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: true,
      description: 'Show Destination Folder',
    },
    hideWhenNoPermissions: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
      description: 'Hide when no permissions',
    }
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
    fileAdded: {
      description: 'Fired when file Added',
    },
    upload: {
      description: 'Upload button is loaded',
    },
  },
});
